import { useState, useEffect } from "react"

import { breakpoints } from "../theme/mediaQueries"
import { debounce } from "../helpers/functions"

const useBreakpointWidth = () => {
  const [breakpointWidth, setBreakpointWidth] = useState({
    min: 0,
    max: 0,
  })

  const getCurrentBreakpointWidth = () => {
    const windowW = window.innerWidth

    let size = breakpoints.xs
    if (windowW > breakpoints.xs && windowW < breakpoints.md) {
      size = breakpoints.sm
    } else if (windowW >= breakpoints.md && windowW < breakpoints.lg) {
      size = breakpoints.md
    } else if (windowW >= breakpoints.lg && windowW < breakpoints.xl) {
      size = breakpoints.lg
    } else if (windowW >= breakpoints.xl) {
      size = breakpoints.xl
    }
    return size
  }

  const getCurrentBreakpointMaxWidth = () => {
    const windowW = window.innerWidth

    let size = breakpoints.xs
    if (windowW > breakpoints.xs && windowW < breakpoints.sm) {
      size = breakpoints.sm
    } else if (windowW >= breakpoints.sm && windowW < breakpoints.md) {
      size = breakpoints.md
    } else if (windowW >= breakpoints.md && windowW < breakpoints.lg) {
      size = breakpoints.lg
    } else if (windowW >= breakpoints.lg) {
      size = breakpoints.xl
    }
    return size
  }

  useEffect(() => {
    const currentBreakpoint = getCurrentBreakpointWidth()
    const currentMaxBreakpoint = getCurrentBreakpointMaxWidth()

    if (breakpointWidth.min === 0) {
      const dim = { min: currentBreakpoint, max: currentMaxBreakpoint }
      setBreakpointWidth(dim)
    }
  }, [])

  useEffect(() => {
    const handleWindowResize = () => {
      const currentBreakpoint = getCurrentBreakpointWidth()
      const currentMaxBreakpoint = getCurrentBreakpointMaxWidth()

      if (breakpointWidth.min !== currentBreakpoint) {
        const dim = { min: currentBreakpoint, max: currentMaxBreakpoint }
        setBreakpointWidth(dim)
      }
    }

    let debounceBreakpointWidth = debounce(handleWindowResize, 500)
    window.addEventListener("resize", debounceBreakpointWidth)
    return () => {
      window.removeEventListener("resize", debounceBreakpointWidth)
      debounceBreakpointWidth = null
    }
  }, [breakpointWidth])

  return breakpointWidth
}

export default useBreakpointWidth
